import jwt_decode from "jwt-decode";

import * as actionTypes from "./actionTypes";
import instance from "./instance";

// Actions
import { commonLinks, linkWithLanguage } from "components/helpers/commonLinks";
import {
  FetchUsher,
  GetSupervisor,
  getApplicantSimplified,
  getCommunityCenter,
  getCompany,
  getParent,
  getStaff,
  handleError,
  resetErrors,
} from "../actions";

export const SetCurrentUser = (token, type) => async (dispatch) => {
  let user = null;

  setAuthToken(token);

  if (type) localStorage.setItem("user_type", type);

  if (token) {
    user = jwt_decode(token);

    if (user.is_parent) await dispatch(getParent(user.user_id));
    if (user.is_company) await dispatch(getCompany(user.user_id));
    if (user.is_community_center)
      await dispatch(getCommunityCenter(user.user_id));
    if (user.is_loyac_staff && type === "is_loyac_staff")
      await dispatch(getStaff(user.user_id));
    if (user.is_lsv_usher && type === "is_lsv_usher")
      await dispatch(FetchUsher(user.email, "email"));
    if (type === "is_applicant" && user.is_applicant) {
      await dispatch(getApplicantSimplified(user.user_id));
    }
    if (type === "is_thirdParty_supervisor" && user.is_thirdParty_supervisor) {
      if (!localStorage.getItem("supervisor_code").length) logout();
      else {
        await dispatch(GetSupervisor(user.user_id));
      }
    }
  }
  await dispatch({
    type: actionTypes.SET_CURRENT_USER,
    payload: user,
  });
};

const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else delete instance.defaults.headers.common.Authorization;
};

export const getCurrentUser = () => {
  let user = null;

  const token = localStorage.getItem("token");

  if (token) {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp > currentTime) user = decodedToken;
  }
  return user;
};
export const checkForExpiredToken = () => async (dispatch) => {
  try {
    await dispatch({
      type: actionTypes.SET_USER_LOADING,
      payload: true,
    });
    const token = localStorage.getItem("token");

    if (token) {
      const user = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      const type = localStorage.getItem("user_type");

      if (user.exp > currentTime) {
        await dispatch(SetCurrentUser(token, type));
      } else {
        // logout the user
        dispatch(logout());
      }
    } else
      await dispatch({
        type: actionTypes.SET_USER_LOADING,
        payload: false,
      });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const Login = (userData, type) => {
  return async (dispatch) => {
    try {
      // login.
      const response = await instance.post("login/", userData);
      const user = response.data;
      const user_info = jwt_decode(user.access);
      if (user_info.is_active)
        if (!user_info[type]) {
          // make sure the user is logging in from the correct form.
          dispatch(handleError({ response: { data: "Incorrect User" } }));
        } else {
          await dispatch(resetErrors());
          if (type === "is_thirdParty_supervisor")
            localStorage.setItem("supervisor_code", userData.supervisor_code);
          dispatch(SetCurrentUser(user.access, type));
          return "success";
        }
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };
};

export const signup = (userData, history) => {
  return async (dispatch) => {
    try {
      const response = await instance.post("/signup/", userData);
      const res = await dispatch(Login(userData, Object.keys(userData)[0]));

      if (userData.is_community_center) {
        dispatch(getCommunityCenter(response.data.id));
      }
      dispatch(resetErrors());
      return res;
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };
};

export const ApplicantsSignup = (userData, history) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      Object.keys(userData).forEach((key) => {
        formData.append(key, userData[key]);
      });
      await instance.post("/applicants/signup/", formData);
      const res = await dispatch(Login(userData, Object.keys(userData)[0]));
      dispatch(resetErrors());
      return res;
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };
};
export const subscribeEmail = (email, status) => {
  return async (dispatch) => {
    try {
      const { data } = await instance.post(
        "v2/applicant/mailchimp/subscription/",
        {
          email,
          subscribing: status,
        }
      );
      dispatch(resetErrors());
      return data;
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/accounts/";
};

export const logoutButton = () => {
  localStorage.clear();
  window.location.href = linkWithLanguage(commonLinks.homeLink);
};

export const updatePassword = (userData) => {
  return async (dispatch) => {
    try {
      await instance.post("/password_reset/confirm/", userData);
      dispatch(resetErrors());
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};
