import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { apiErrorCatcher } from "./common-action-utils";
import {
  forgotPasswordAPI,
  resetPasswordFromKeyAPI,
} from "redux/services/newAuth";

const initialState = {
  loading: false,
  formLoading: false,
  error: null,
  formSuccess: false,
};

export const forgotPassword = createAsyncThunk(
  "api/forgotPassword",
  async (payload: any, thunkAPI) => {
    // payload contains {"email": "email@mail.com"}

    try {
      const response = await forgotPasswordAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetPasswordFromKey = createAsyncThunk(
  "api/resetPasswordFromKey",
  async (payload: any, thunkAPI) => {
    // payload contains {"key": "key", "password":"12321" }

    try {
      const response = await resetPasswordFromKeyAPI(payload);
      return response.data;
    } catch (error: any) {
      const response = error?.response || {};
      const { status, data } = response;
      if (status === 401) {
        // this is stil valid return a success data
        return data;
      }

      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const newAuthSlice = createSlice({
  name: "newAuthSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle the patchRegistration async thunk
      .addCase(forgotPassword.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        forgotPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(forgotPassword.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })

      .addCase(resetPasswordFromKey.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        resetPasswordFromKey.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        resetPasswordFromKey.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      );
  },
});

export default newAuthSlice.reducer;
