import rootReducer from "./reducers";
import discountSlice from "./slices/discountSlice";
import alertSlice from "./slices/alertSlice";
import registrationSlice from "./slices/registrationSlice";
import coreProgramSlice from "./slices/coreProgramSlice";
import applicantSlice from "./slices/applicantSlice";
import programSlice from "./slices/programSlice";
import productSlice from "./slices/productSlice";
import inquiriesSlice from "./slices/inquiriesSlice";
import newAuthSlice from "./slices/newAuthSlice";
import navbarSlice from "components/navigation/navbar/slice";
import locationSlice from "./slices/locationSlice";
import { checkForExpiredToken } from "./actions";
import { configureStore } from "@reduxjs/toolkit"; // Import configureStore from Redux Toolkit

// Use the existing thunk middleware with Redux Toolkit's configureStore
const store = configureStore({
  reducer: {
    ...rootReducer,
    rootDiscounts: discountSlice,
    rootCorePrograms: coreProgramSlice,
    alert: alertSlice,
    rootRegistrationSlice: registrationSlice,
    rootApplicantSlice: applicantSlice,
    rootProgramSlice: programSlice,
    rootProductSlice: productSlice,
    rootInquiriesSlice: inquiriesSlice,
    navbar: navbarSlice,
    rootLocationSlice: locationSlice,
    rootNewAuthSlice: newAuthSlice,
  },
});

store.dispatch(checkForExpiredToken());

// Infer the `RootState` and `AppDispatch` types from the store itself
//@ts-ignore
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export default store;
